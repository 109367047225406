import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/kicker_pro_logo.png";

function NavBar({ isLoggedIn, isSessionActive, handleLogout }) {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const startNewSession = () => {
    navigate("session");
  };

  const endSession = () => {
    localStorage.removeItem("activeSession");
    navigate("/");
  };

  // Determine if the current path matches the given path
  const isActive = (path) => {
    console.log("path: ", path);
    console.log("location.pathname: ", location.pathname);
    return location.pathname === path;
  };

  return (
    <header className="w-full bg-kicker-green-50 flex flex-row justify-between p-8 items-center shadow-md">
      <div>
        <Link to="/" className="block">
          <img className="w-1/2" src={logo} alt="Kicker Pro Logo" />
        </Link>
      </div>
      {isLoggedIn ? (
        <div className="flex ">
          <div
            className={`flex items-center mr-4 h-10 px-2 py-4 col-span-2 rounded-lg text-sm space-x-2 cursor-pointer ${
              isActive("/admin") ? "bg-white text-black" : "text-black"
            }`}
          >
            <a
              onClick={() => {
                navigate("admin");
              }}
            >
              Admin
            </a>
          </div>

          <div
            className={`flex items-center mr-4 h-10 px-4 col-span-2 rounded-lg text-sm space-x-2 cursor-pointer ${
              isActive("/") ? "bg-white text-black" : "text-black"
            }`}
          >
            <a
              onClick={() => {
                navigate("/");
              }}
            >
              Dashboard
            </a>
          </div>
          <div
            className={`flex items-center mr-4 h-10 px-4 py-2 col-span-2 rounded-lg text-sm space-x-2 cursor-pointer ${
              isActive("/analysis") ? "bg-white text-black" : "text-black"
            }`}
          >
            <a
              onClick={() => {
                navigate("analysis");
              }}
            >
              Compare Profiles
            </a>
          </div>
          <div
            className={`flex items-center mr-4 h-10 px-4 py-2 col-span-2 rounded-lg text-sm space-x-2 cursor-pointer ${
              isActive("/session") ? "hidden" : "bg-kicker-green-700 text-white"
            }`}
          >
            <a onClick={startNewSession}>Start a Session</a>
          </div>
          {isSessionActive && location.pathname === "/session" && (
            <div className="flex items-center mr-4 px-4 py-4 col-span-2 border rounded-lg text-sm space-x-2 bg-red-600 text-white cursor-pointer">
              <a onClick={endSession}>End Session</a>
            </div>
          )}
          <div className="flex items-center px-4 py-2 col-span-2 rounded-lg text-sm space-x-2 bg-slate-800 text-white cursor-pointer">
            <a onClick={handleLogout}>Logout</a>
          </div>
        </div>
      ) : null}
    </header>
  );
}

export default NavBar;
