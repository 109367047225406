import React from "react";

const DashboardButton = ({ id, selected, onSelect, children }) => {
  const buttonClass = `flex items-center px-4 py-4 col-span-2 border rounded-lg text-sm space-x-2 ${
    selected ? "bg-slate-800 text-white" : "bg-white"
  }`;

  // Call the passed onSelect function with the button's id on click
  const handleClick = () => {
    onSelect(id);
  };

  return (
    <button className={buttonClass} onClick={handleClick}>
      <span
        className={`block w-2 h-2 border-2 rounded-full ${
          selected ? "border-white" : "border-gray-600"
        } `}
      ></span>
      <span>{children}</span>
    </button>
  );
};

export default DashboardButton;
