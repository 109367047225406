import { useState, useEffect, useRef } from "react";
import DashboardButton from "../../Dashboard/components/DashboardButton";
import field2 from "../../../assets/images/field_w_background.png";
import fullField from "../../../assets/images/full_field.png";
import tee from "../../../assets/images/tee.png";
import undo from "../../../assets/images/undo.png";
import wind from "../../../assets/images/wind.png";
import KickDataEntryField1 from "./KickDataEntryField1";
import { deleteKick } from "../../../utils/api";

const KickDataEntry = ({ players, session }) => {
  const boxKickTypes = [
    { id: 1, label: "Contestable" },
    { id: 2, label: "Territory" },
  ];
  const kickTypes = [
    { id: 1, label: "Goal Kicks" },
    { id: 2, label: "Drop Kicks" },
    { id: 3, label: "Restarts" },
    { id: 4, label: "Box Kicks" },
    { id: 5, label: "Kick For Touch" },
  ];

  const [boxKickType, setBoxKickType] = useState(1);
  const [kickTypeId, setKickTypeId] = useState(1);
  const [distanceToCenter, setDistanceToCenter] = useState(0);
  const [distanceToPost, setDistanceToPost] = useState(0);
  const [distanceToTryLine, setDistanceToTryLine] = useState(0);
  const [lastKick, setLastKick] = useState(null);
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(0);
  const playerListRef = useRef(null);

  const handleBoxKickTypeSelect = (id) => {
    setBoxKickType(id);
  };

  const handleKickTypeSelect = (id) => {
    setKickTypeId(id);
  };

  const handleUndo = () => {
    deleteKick(lastKick.id);
    setLastKick(null);
  };

  useEffect(() => {
    console.log("lastKick updated: ", lastKick);
  }, [kickTypeId, lastKick]);

  return (
    <div className="grid bg-gray-200">
      <div className="grid grid-cols-12 gap-4 ml-8 mt-8">
        {kickTypes.map(({ id, label }) => (
          <DashboardButton
            key={id}
            id={id}
            selected={kickTypeId === id}
            onSelect={handleKickTypeSelect}
          >
            {label}
          </DashboardButton>
        ))}
      </div>

      <div className="flex justify-between ml-8 my-4 items-center">
        {kickTypeId in [1, 2, 3] && (
          <div className="bg-white rounded-lg p-4 flex text-center divide-x">
            <div className="flex-col px-4">
              <div className="font-bold">{distanceToTryLine}M</div>
              <div className="text-sm text-slate-800">FROM THE TRY LINE</div>
            </div>
            <div className="flex-col px-4">
              <div className="font-bold">{distanceToCenter}M</div>
              <div className="text-sm text-slate-800">FROM CENTRE FIELD</div>
            </div>
            <div className="flex-col px-8">
              <div className="font-bold">{distanceToPost}M</div>
              <div className="text-sm text-slate-800">TO POST</div>
            </div>
          </div>
        )}

        {kickTypeId === 4 && (
          <div className="flex justify-between mr-8 my-4 items-center space-x-4">
            {boxKickTypes.map(({ id, label }) => (
              <DashboardButton
                key={id}
                id={id}
                selected={boxKickType === id}
                onSelect={handleBoxKickTypeSelect}
              >
                {label}
              </DashboardButton>
            ))}
          </div>
        )}

        <div className="flex">
          <div className="bg-white rounded-lg flex-col w-12 h-12 justify-around items-center flex mx-2 p-2">
            <img src={tee} className="w-[15px]" alt="kicking tee icon" />
            <div className="text-xs text-slate-800">TEE</div>
          </div>
          <div className="bg-white rounded-lg flex-col w-12 h-12 justify-around items-center flex mx-2 p-2">
            <img src={wind} className="w-[15px]" alt="wind direction icon" />
            <div className="text-xs text-slate-800">WIND</div>
          </div>
          {lastKick && (
            <div
              onClick={handleUndo}
              className="bg-white rounded-lg flex-col w-12 h-12 justify-around items-center flex ml-2 mr-16 p-2"
            >
              <img src={undo} className="w-[15px]" alt="undo icon" />
              <div className="text-xs text-slate-800">UNDO</div>
            </div>
          )}
        </div>
      </div>

      <div className="grid justify-center">
        <KickDataEntryField1
          boxKickType={
            boxKickTypes.filter(({ id, label }) => id === boxKickType)[0]
          }
          kickType={kickTypeId}
          player={players[selectedPlayerIndex]}
          src={kickTypeId !== 5 ? field2 : fullField}
          setDistanceToCenter={setDistanceToCenter}
          setDistanceToPost={setDistanceToPost}
          setDistanceToTryLine={setDistanceToTryLine}
          setLastKick={setLastKick}
          session={session}
        />
      </div>
      <div className="w-screen overflow-hidden mb-32 mx-auto">
        <ul ref={playerListRef} className="flex justify-center min-w-full">
          {players?.map((player, index) => (
            <li
              key={index}
              onClick={() => setSelectedPlayerIndex(index)}
              className="flex flex-col items-center m-4 snap-center"
            >
              <div
                className={`rounded-full ${
                  index === selectedPlayerIndex
                    ? "border-kicker-green-700"
                    : "border-transparent"
                } border-4 w-24 h-24 overflow-hidden flex justify-center items-center`}
              >
                <img
                  src={player.image}
                  alt={player.name}
                  className="object-cover min-w-full min-h-full"
                />
              </div>
              <p className="text-center">{player.name}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default KickDataEntry;
