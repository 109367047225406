import React, { useEffect, useState } from "react";

import { getPlayerProfiles } from "../../../utils/api";
import CircularProgress from "../../../components/CircularProgress";

// // Mock data structure similar to what's seen in the image
// const players = [
//   { name: "Bernard Foley", kicks: 238, percentage: 81, img: img1 },
//   { name: "Johnny Wilkinson", kicks: 214, percentage: 80, img: img3 },
//   { name: "Percy Montgomery", kicks: 199, percentage: 80, img: img4 },
//   { name: "Dan Carter", kicks: 157, percentage: 80, img: img5 },
//   { name: "Diego Dominguez", kicks: 168, percentage: 80, img: img6 },
//   { name: "Ronan O'Gara", kicks: 56, percentage: 80, img: img7 },
//   { name: "Leigh Halfpenny", kicks: 68, percentage: 80, img: img1 },
//   { name: "Bernard Foley", kicks: 238, percentage: 81, img: img1 },
//   { name: "Johnny Wilkinson", kicks: 214, percentage: 80, img: img3 },
//   { name: "Percy Montgomery", kicks: 199, percentage: 80, img: img4 },
//   { name: "Dan Carter", kicks: 157, percentage: 80, img: img5 },
//   { name: "Diego Dominguez", kicks: 168, percentage: 80, img: img6 },
//   { name: "Ronan O'Gara", kicks: 56, percentage: 80, img: img7 },
//   { name: "Leigh Halfpenny", kicks: 68, percentage: 80, img: img2 },
//   // ... other players
// ];

const PlayerRankingList = ({ players }) => {
  console.log("[DEBUG] players: ", players);
  return (
    <div className="h-[30%] bg-white border mt-4 rounded-lg shadow-md p-4 mb-8 overflow-y-auto">
      <ul className="divide-y divide-gray-200">
        {players?.map((player, index) => (
          <li key={index} className="py-3 flex items-center justify-between">
            <div className="flex items-center">
              <div className="rounded-full bg-gray-300 w-10 h-10 mr-4 overflow-hidden flex justify-center items-center">
                <img
                  src={player.image}
                  alt={player.name}
                  className="object-cover min-w-full min-h-full"
                />
              </div>

              <div>
                <p className="font-semibold">{player.name}</p>
                <p className="text-gray-600">{player.totalKicks} kicks</p>
              </div>
            </div>
            <div className="w-12 h-12">
              <CircularProgress
                percent={player.successRate}
                size={40}
                strokeWidth={1}
                variant="xs"
                percentColor="#000"
                primary="#000"
                secondary="#E0E0E0"
                dy="0.4em"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlayerRankingList;
