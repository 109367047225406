import { useEffect, useState } from "react";
import CircularProgress from "../../../components/CircularProgress";

function DashboardLeftTile({ kicks, kickTypeId }) {
  const [percentMade, setPercentMade] = useState(0); // Initialize percentMade to 0 for initial state
  const [avgGain, setAvgGain] = useState(0); // Initialize avgGain to 0

  useEffect(() => {
    function calculateSuccessPercentage() {
      if (!kicks || kicks.length === 0) {
        setPercentMade(0);
        setAvgGain(0); // Set avgGain to 0 if no kicks available
        return;
      }

      const totalKicks = kicks.length;
      const successfulKicks = kicks.filter((kick) => kick.success).length;

      // Calculate the success percentage
      const successPercentage = (successfulKicks / totalKicks) * 100;
      setPercentMade(Math.floor(successPercentage));

      // Calculate average distance gained
      const totalDistance = kicks.reduce(
        (acc, kick) => acc + kick.distance_gained,
        0
      );
      const averageDistance = totalDistance / totalKicks;
      setAvgGain(Math.round(averageDistance)); // Round to the nearest integer
    }

    calculateSuccessPercentage();
  }, [kicks]); // This ensures calculations run when `kicks` updates

  return (
    <div className="h-full p-8 bg-gray-100 border rounded-lg flex flex-col justify-center items-center">
      {kickTypeId === 5 && (
        <div className="flex flex-col items-center pb-8">
          <h1 className="text-[28px] font-bold">{avgGain}M</h1>
          <p className="text-sm">AVERAGE GAIN</p>{" "}
          {/* Display the average gain as an integer */}
        </div>
      )}
      <CircularProgress
        percent={percentMade}
        size={kickTypeId === 5 ? 100 : 200}
        strokeWidth={10}
        subtitle="Total Made"
        variant={kickTypeId === 5 ? "s" : "xl"}
      />
    </div>
  );
}

export default DashboardLeftTile;
