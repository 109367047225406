const updateInitialPosition = (
  imgRef,
  setFieldCenter,
  setFieldZones,
  setKickPosition,
  setLoaded,
  setPosition,
  setRestartZones,
  isSession
) => {
  if (imgRef.current) {
    const rect = imgRef.current.getBoundingClientRect();
    const outOfBounds = 0.05764796311;
    const left5mWidth = 0.0855 * rect.width;
    const left15mWidth = 0.1748466258 * rect.width;
    const centerBoxWidth = left5mWidth * 2.5;
    const boxHeight = rect.height * 0.2;
    const leftSideline =
      outOfBounds * rect.width + (isSession ? rect.width * 0.5 : 0);
    const bottomZoneY = rect.height * 0.555;
    const topZoneY = rect.height * 0.26;
    const rightSideLine =
      rect.width * (isSession ? 1.494 : 0.995) - outOfBounds * rect.width;

    // Set restart zone positions
    const zoneInfo = [
      {
        id: "l_4",
        label: "4",
        kickingArea: 5,
        x: leftSideline,
        y: topZoneY,
        w: left5mWidth * 2,
        h: boxHeight,
      },
      {
        id: "l_3",
        label: "3",
        kickingArea: 4,
        x: leftSideline,
        y: bottomZoneY,
        w: left5mWidth,
        h: boxHeight,
      },
      {
        id: "l_2",
        label: "2",
        kickingArea: 3,
        x: leftSideline + left5mWidth,
        y: bottomZoneY,
        w: left15mWidth,
        h: boxHeight,
      },
      {
        id: "c_5",
        label: "5",
        kickingArea: 2,
        x: rect.width * (isSession ? 1 : 0.5) - centerBoxWidth / 2,
        y: topZoneY,
        w: centerBoxWidth,
        h: boxHeight,
      },
      {
        id: "c_1",
        label: "1",
        kickingArea: 1,
        x: rect.width * (isSession ? 1 : 0.5) - centerBoxWidth / 2,
        y: bottomZoneY,
        w: centerBoxWidth,
        h: boxHeight,
      },
      {
        id: "r_4",
        label: "4",
        kickingArea: 8,
        x: rightSideLine - left5mWidth * 2,
        y: topZoneY,
        w: left5mWidth * 2,
        h: boxHeight,
      },
      {
        id: "r_3",
        label: "3",
        kickingArea: 7,
        x: rightSideLine - left5mWidth,
        y: bottomZoneY,
        w: left5mWidth,
        h: boxHeight,
      },
      {
        id: "r_2",
        label: "2",
        kickingArea: 6,
        x: rightSideLine - left5mWidth - left15mWidth,
        y: bottomZoneY,
        w: left15mWidth,
        h: boxHeight,
      },
    ];

    setRestartZones(zoneInfo);

    if (isSession) {
      // Set Field Zones (eg Left 5m, Left 15m, Center, Right 15m , Right 5m)
      const _fieldZones = {
        left5m: { x1: leftSideline, x2: leftSideline + left5mWidth },
        left15m: {
          x1: leftSideline + left5mWidth,
          x2: leftSideline + left5mWidth + left15mWidth,
        },
        center: {
          x1: leftSideline + left5mWidth + left15mWidth,
          x2: rightSideLine - left5mWidth - left15mWidth,
        },
        right15m: {
          x1: rightSideLine - left5mWidth - left15mWidth,
          x2: rightSideLine - left5mWidth,
        },
        right5m: { x1: rightSideLine - left5mWidth, x2: rightSideLine },
      };

      setFieldZones(_fieldZones);
      setKickPosition({
        x1: 0.5,
        y1: 0.5,
        x2: 0.6,
        y2: 0.5,
      });
      setFieldCenter({
        x: 0.5,
        y: 0.5,
      });
      setPosition({
        x: rect.width - 16,
        y: rect.height / 2 - 16,
        x2: rect.width - 16 + 70,
        y2: rect.height / 2 - 16,
      });
    }
    setLoaded(true);
  }
};

export function getZone(fieldZones, x) {
  if (x >= fieldZones.left5m.x1 && x < fieldZones.left5m.x2) {
    return "Left 5m";
  } else if (x >= fieldZones.left15m.x1 && x < fieldZones.left15m.x2) {
    return "Left 15m";
  } else if (x >= fieldZones.center.x1 && x < fieldZones.center.x2) {
    return "Center";
  } else if (x >= fieldZones.right15m.x1 && x < fieldZones.right15m.x2) {
    return "Right 15m";
  } else if (x >= fieldZones.right5m.x1 && x <= fieldZones.right5m.x2) {
    return "Right 5m";
  }
  return "Out of Bounds";
}

export default updateInitialPosition;
