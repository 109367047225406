import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "../../../components/CircularProgress";
import field2 from "../../../assets/images/field_w_background.png";
import fullField from "../../../assets/images/full_field.png";
import KickInfoPopup from "./KickInfoPopup";
import updateInitialPosition from "../../../utils/map_helpers";
import ZonePercentages from "./ZonePercentages";

const RugbyUnionFieldChart = ({ background = false, kicks, kickTypeId }) => {
  const imgRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [processedKicks, setProcessedKicks] = useState(null);
  const [zonePercentages, setZonePercentages] = useState([]);
  const [selectedKick, setSelectedKick] = useState(null);
  const [restartZones, setRestartZones] = useState(null);

  const restartZoneLabels = {
    1: "Centre Zone 1",
    2: "Centre Zone 5",
    3: "Left Zone 2",
    4: "Left Zone 3",
    5: "Left Zone 4",
    6: "Right Zone 2",
    7: "Right Zone 3",
    8: "Right Zone 4",
  };

  const onImgLoad = () => {};

  const handleKickClick = (kick) => {
    setSelectedKick(kick);
  };

  const closePopup = () => {
    setSelectedKick(null);
  };

  useEffect(() => {
    if (kickTypeId === 3) {
      updateInitialPosition(
        imgRef,
        null,
        null,
        null,
        setLoaded,
        null,
        setRestartZones,
        false
      );
    }
    let zoneStats;

    if (kickTypeId !== 3) {
      zoneStats = {
        "left 5m": { total: 0, successful: 0 },
        "left 15m": { total: 0, successful: 0 },
        center: { total: 0, successful: 0 },
        "right 15m": { total: 0, successful: 0 },
        "right 5m": { total: 0, successful: 0 },
      };
    } else {
      zoneStats = {
        1: { total: 0, successful: 0 },
        2: { total: 0, successful: 0 },
        3: { total: 0, successful: 0 },
        4: { total: 0, successful: 0 },
        5: { total: 0, successful: 0 },
        6: { total: 0, successful: 0 },
        7: { total: 0, successful: 0 },
        8: { total: 0, successful: 0 },
      };
    }

    // Step 1: Group kicks by session_id
    const groupedKicks = kicks.reduce((acc, kick) => {
      const { session_id } = kick;
      if (!acc[session_id]) {
        acc[session_id] = [];
      }
      acc[session_id].push(kick);
      return acc;
    }, {});

    // Step 2: Sort each group by created_at and assign kick order
    const _processedKicks = Object.values(groupedKicks).flatMap(
      (sessionKicks) => {
        // Sort by created_at timestamp
        sessionKicks.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        // Step 3: Assign kick order
        return sessionKicks.map((kick, index) => ({
          ...kick,
          kick_order: index + 1, // Adding the kick order (1-based index)
        }));
      }
    );

    _processedKicks?.forEach((kick) => {
      let { id, kicking_area, zone, success } = kick;

      zone = kickTypeId === 3 ? kicking_area : zone.toLowerCase();
      // if (!kicking_area) {
      //   console.log("kick zone: ", zone);
      // }
      if (!zoneStats[zone]) {
        zoneStats[zone] = { total: 0, successful: 0 };
      }
      zoneStats[zone].total += 1;
      if (success) zoneStats[zone].successful += 1;
    });

    const calculatedZones = Object.entries(zoneStats).map(
      ([id, { total, successful }]) => {
        // console.log("id: ", id);
        // console.log("label id: ", restartZoneLabels[id]);
        return {
          label: kickTypeId === 3 ? restartZoneLabels[id] : id,
          percentage: total === 0 ? 0 : Math.floor((successful / total) * 100),
        };
      }
    );

    setProcessedKicks(_processedKicks);
    setZonePercentages(calculatedZones);
    console.log("calculatedZones: ", calculatedZones);
  }, [kicks, kickTypeId]);

  return (
    <div className="h-full flex flex-col items-center bg-green-700 p-4 rounded-lg">
      {zonePercentages?.length > 0 ? (
        <ZonePercentages kickTypeId={kickTypeId} stats={zonePercentages} />
      ) : (
        <></>
      )}

      <div className="relative w-full">
        <img
          ref={imgRef}
          onLoad={onImgLoad}
          src={kickTypeId !== 5 ? field2 : fullField}
          alt="Rugby Field"
          className=" w-full"
        />
        {loaded &&
          kickTypeId === 3 &&
          restartZones &&
          restartZones.map(({ id, label, x, y, w, h }) => {
            return (
              <div
                key={id}
                style={{
                  position: "absolute",
                  left: `${x}px`,
                  top: `${y}px`,
                  width: w,
                  height: h,
                }}
                className="bg-slate-200 border border-slate-500 border-dotted flex justify-center items-center font-bold text-slate-400 text-md"
              >
                {label}
              </div>
            );
          })}
        <svg
          className="absolute top-0 left-0 w-full h-full"
          style={{ zIndex: 0 }}
        >
          {processedKicks?.map((kick, index) =>
            kick.kick_type === 5 && kick.x2 != null && kick.y2 != null ? (
              <line
                key={index}
                x1={`${kick.x1}%`}
                y1={`${kick.y1}%`}
                x2={`${kick.x2}%`}
                y2={`${kick.y2}%`}
                stroke={kick.success === true ? "#21C55D" : "#EF4444"}
                strokeWidth="2"
              />
            ) : null
          )}
        </svg>
        {processedKicks?.map((kick, index) => (
          <div
            key={index}
            onClick={() => {
              console.log("kick info: ", kick);
              handleKickClick(kick);
            }}
            className={`absolute w-2 h-2 rounded-full ${
              kick.success === true ? "bg-green-500" : "bg-red-500"
            }`}
            style={{
              left: `${kick.x1}%`,
              top: `${kick.y1}%`,
              transform: "translate(-50%, -50%)",
            }}
          ></div>
        ))}
        {processedKicks?.map((kick, index) =>
          kick.x2 && kick.y2 ? (
            <div
              key={index}
              className={`absolute w-2 h-2 rounded-full ${
                kick.success === true ? "bg-green-500" : "bg-red-500"
              }`}
              style={{
                left: `${kick.x2}%`,
                top: `${kick.y2}%`,
                transform: "translate(-50%, -50%)",
              }}
            ></div>
          ) : null
        )}
        {selectedKick && (
          <KickInfoPopup kick={selectedKick} onClose={closePopup} />
        )}
      </div>
    </div>
  );
};

export default RugbyUnionFieldChart;
