import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import CircularProgress from "../../../components/CircularProgress";
import { useEffect, useState } from "react";

export default function KickGraphMonthly({ data }) {
  const [chartConfig, setChartConfig] = useState();
  const [percentChange, setPercentChange] = useState();

  const initializeChart = async (kicks) => {
    if (!kicks) {
      setChartConfig(null);
      setPercentChange(null);
      return;
    }

    // Get the current date and subtract 12 months
    const currentDate = new Date();
    const twelveMonthsAgo = new Date(currentDate);
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

    const groupedData = kicks.reduce((acc, kick) => {
      const date = new Date(kick.created_at);

      // Only consider kicks within the last 12 months
      if (date < twelveMonthsAgo) return acc;

      const month = date.toLocaleString("default", {
        month: "short",
        year: "numeric",
      });

      if (!acc[month]) {
        acc[month] = { made: 0, missed: 0 };
      }

      if (kick.result_type === "Made") {
        acc[month].made += 1;
      } else {
        acc[month].missed += 1;
      }

      return acc;
    }, {});

    // Sort months by date to calculate the percent change
    const sortedMonths = Object.keys(groupedData).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    // First, calculate the success rates
    const successRateData = sortedMonths.map((month) => {
      const { made, missed } = groupedData[month];
      const totalKicks = made + missed;
      const successRate = totalKicks > 0 ? (made / totalKicks) * 100 : 0;

      return {
        month,
        successRate,
        percentChange: null, // Initialize percentChange with null
      };
    });

    if (!successRateData?.length) {
      setChartConfig(null);
      setPercentChange(null);
      return;
    }

    // Then, calculate the percent change
    for (let index = 1; index < successRateData?.length; index++) {
      const previousSuccessRate = successRateData[index - 1].successRate;
      const currentSuccessRate = successRateData[index].successRate;

      if (previousSuccessRate !== null) {
        const percentChange =
          ((currentSuccessRate - previousSuccessRate) / previousSuccessRate) *
          100;
        successRateData[index].percentChange = percentChange;
      }
    }

    const kickData = {
      kicks: successRateData.map((data) => data.successRate), // Array of success rates
      categories: successRateData.map((data) => data.month), // Array of months
    };

    const mostRecentPercentChange =
      successRateData[successRateData?.length - 1].percentChange;
    setPercentChange(mostRecentPercentChange);

    const _chartConfig = {
      type: "bar",
      height: "200px", // Set chart height to auto
      series: [
        {
          name: "Success Rate (%)",
          data: kickData.kicks,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#020617"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            borderRadius: 6,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "10px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: kickData.categories,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
            formatter: (value) => `${value}%`, // Display percentage on y-axis
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 5,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          theme: "dark",
        },
      },
    };

    setChartConfig(_chartConfig);
  };

  useEffect(() => {
    initializeChart(data.kicks);
  }, [data]);

  if (!data?.kicks) {
    return <></>;
  }

  return (
    <div className="col-span-3">
      <Card className=" h-auto rounded-lg">
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="flex flex-col gap-4 rounded-none md:flex-col md:items-center md:justify-between w-full p-4"
        >
          <div className="flex items-center justify-between mb-2 w-full">
            <div className="flex">
              <div className="rounded-full bg-gray-300 w-10 h-10 mr-4 overflow-hidden flex justify-center items-center">
                <img
                  src={data?.image}
                  alt={data?.name}
                  className="object-cover min-w-full min-h-full"
                />
              </div>
              <div>
                <h2 className="text-sm font-semibold mr-8">{data?.name}</h2>
                <p className="text-xs text-gray-500">
                  {data?.totalKicks} kicks
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <CircularProgress
                percent={data?.successRate}
                size={40}
                strokeWidth={1}
                variant="xs"
                percentColor="#000"
                primary="#000"
                secondary="#E0E0E0"
                dy="0.4em"
              />
            </div>
          </div>
          {percentChange !== null && (
            <div className="text-center mb-4">
              <div className="text-2xl font-bold flex items-center justify-center">
                {Math.round(percentChange) + "%"}
                {percentChange >= 0 ? (
                  <span className="text-green-500 ml-2">▲</span>
                ) : (
                  <span className="text-red-500 ml-2">▼</span>
                )}
              </div>
              <div className="text-sm text-gray-500">Percent Change</div>
            </div>
          )}
        </CardHeader>
        <CardBody className="px-2 pb-0">
          {chartConfig ? <Chart {...chartConfig} /> : <></>}
        </CardBody>
      </Card>
    </div>
  );
}
