import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import CircularProgress from "../../../components/CircularProgress";
import { useEffect, useState } from "react";

export default function KickGraphBasic({ data }) {
  const [chartConfig, setChartConfig] = useState();

  const initializeChart = async (kicks) => {
    if (!kicks) {
      setChartConfig(null);
      return;
    }
    const groupedData = await kicks?.reduce((acc, kick) => {
      const { result_type } = kick;

      // Ignore "Made" kicks
      if (result_type === "Made") return acc;

      if (!acc[result_type]) {
        acc[result_type] = 0;
      }

      acc[result_type] += 1;

      return acc;
    }, {});

    console.log("[DEBUG] Object.keys(groupedData): ", Object.keys(groupedData));

    if (!Object.values(groupedData)?.length) {
      setChartConfig(null);
      return;
    }
    const kickData = {
      kicks: Object.values(groupedData), // Array of counts for each result type
      categories: Object.keys(groupedData), // Array of result types
    };

    const _chartConfig = {
      type: "bar",
      height: "200px", // Set chart height to auto
      series: [
        {
          name: "Kicks",
          data: kickData.kicks,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        title: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#020617"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            borderRadius: 6,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#616161",
              fontSize: "10px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
          categories: kickData.categories,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#616161",
              fontSize: "12px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#dddddd",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: 5,
            right: 20,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          theme: "dark",
        },
      },
    };
    setChartConfig(_chartConfig);
  };

  useEffect(() => {
    initializeChart(data.kicks);
  }, [data]);

  return (
    <div className="col-span-3 mb-2">
      <Card className=" h-auto rounded-lg">
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="flex flex-col gap-4 rounded-none md:flex-col md:items-center md:justify-between w-full p-4"
        >
          <div className="flex items-center justify-between mb-2 w-full">
            <div className="flex">
              <div className="rounded-full bg-gray-300 w-10 h-10 mr-4 overflow-hidden flex justify-center items-center">
                <img
                  src={data.image}
                  alt={data?.name}
                  className="object-cover min-w-full min-h-full"
                />
              </div>
              <div>
                <h2 className="text-sm font-semibold mr-8">{data?.name}</h2>
                <p className="text-xs text-gray-500">
                  {data?.totalKicks} kicks
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <CircularProgress
                percent={data?.successRate}
                size={40}
                strokeWidth={1}
                variant="xs"
                percentColor="#000"
                primary="#000"
                secondary="#E0E0E0"
                dy="0.4em"
              />
            </div>
          </div>
          <div className="text-center mb-4">
            <div className="text-2xl font-bold">{data?.missedKicks}</div>
            <div className="text-sm text-gray-500">TOTAL KICKS MISSED</div>
          </div>
        </CardHeader>
        <CardBody className="px-2 pb-0">
          {chartConfig ? <Chart {...chartConfig} /> : <></>}
        </CardBody>
      </Card>
    </div>
  );
}
